.projectTaskKanbanToolBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding-left: 10px;
}

.projectTaskKanbanBreadCrumb {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.projectTaskKanbanTitle {
	font-size: 12px;
	font-family: Manrope-Bold;
	color: #5c5c5c;
}

.arrowIcon {
	color: #5c5c5c;
	width: 21px;
	padding-left: 7px;
}

.projectTaskKanbanProjectName {
	color: #4285f4;
	font-size: 12px;
	font-family: Manrope-Bold;
	padding-left: 14px;
	width: 230px;
}

.projectTaskKanbanToolbarContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-left: 10px;
}

.projectTaskKanbanFilterButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #4285f4;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}

.projectTaskKanbanIconButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #888888;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}

.projectTaskKanbanActiveIconButton {
	color: #4285f4;
	border: 1px solid #4285f4;
}

.projectTaskKanbanAddTaskButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 14px;
	min-width: 108px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.projectTaskKanbanUploadTaskButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 14px;
	min-width: 108px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	gap: 6px;
	margin-left: 4px;
}

.projectTaskKanbanAddTaskButton:hover {
	background-color: #4285f4;
}

.projectTaskKanbanAssigneeFilterContainer {
	background: #ffffff;
	height: 36px;
	margin-left: 10px;
	align-items: left;
}

.projectTaskKanbanFilterContainer {
	display: flex;
	align-items: left;
}

.projecttaskkanbanfilterbutton {
	margin-right: 10px;
}

.projectTaskContainer {
	text-align: left;
	padding-bottom: 10px;
}

.projectTaskPriorityBadge {
	padding: 2px 5px;
	border-radius: 10px;
	margin-left: 10px;
	text-align: center;
}

.projectTaskPriorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}

.projectTaskPriorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}

.projectTaskPriorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}

.projectTaskDescriptionStyle {
	padding-left: 10px;
}

.projectTaskHeaderStyle {
	width: 216px;
	color: #292929;
	font-size: 12px;
	font-family: Manrope-semibold;
	padding: 0px 14px 4px 14px;
	margin-left: 6px;
}

.projectTaskDescription {
	width: 300px;
	font: normal normal 600 12px/25px Manrope;
	color: #6f6f6f;
	padding: 12px 14px 12px 15px;
	margin-left: 6px;
}

.projectTaskIdStyle {
	display: flex;
	justify-content: space-between;
}

.projectCategoryContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 22px;
}

.projectTaskCategoryName {
	font-size: 12px;
	margin: 0px 0px 3px 11px;
	background: #c0b6f2;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	color: #403294;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 150px;
	cursor: pointer;
	font-family: Manrope-medium;
}

.projectTaskContainerStyle {
	padding-left: 8px;
}

.avatar {
	height: 25;
	width: 25;
	cursor: pointer;
}

.projectTaskCreatedDate {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
	cursor: pointer;
}

.projectTaskCardContainer {
	width: 100%;
	min-height: 110px;
	background: #ffffff 0% 0% no-repeat padding-box;
}

.projectTaskOverdueCardContainer {
	width: 100%;
	border-left: 3px solid #ea4335;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
}

.projectTaskPrioritUNdefined {
	padding-right: 46px;
}

.projectTaskChipStyle {
	font-size: 10px;
	/* padding: 4px 8px 4px 8px; */
	border-radius: 10px;
	cursor: pointer;
}

.projectTaskCardFooter {
	padding: 7px 4px 8px 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.projectTaskNormalDue {
	font: normal normal 600 10px/20px Manrope;
	color: #34a853;
	max-width: 50px;
}

.projectTaskOverDue {
	font: normal normal 600 10px/20px Manrope;
	color: #ea4335;
}

.projectTaskDueToday {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
}

.taskIcon {
	width: 16px !important;
	height: 16px !important;
	margin-top: 10px !important;
	color: #4285f4;
	margin-right: 8px !important;
	cursor: pointer;
}

.taskIdContainer {
	font: normal normal 800 10px/20px Manrope;
	color: #888888;
	margin-top: 8px;
	text-transform: uppercase;
	margin-left: 7px;
	padding-left: 8px;
}

.subTask {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.numberofSubTask {
	width: 12px;
	height: 12px;
	background: #817b7b;
	font-size: 8px;
	font-family: Manrope-extrabold;
	color: white;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	right: -16px;
	bottom: 5px;
}

.hoverText {
	display: flex !important;
	justify-content: flex-start;
	margin-left: 48px;
	margin-top: 5px;
	color: #4285f4;
	font: normal normal 600 14px/20px Manrope;
	cursor: pointer;
}

.hoverText:hover {
	text-decoration: underline;
	color: #4285f4;
	cursor: pointer;
}

.progressBarContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.backButton {
	width: 89px;
	height: 36px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	opacity: 1;
	font: normal normal bold 12px/32px Manrope;
	letter-spacing: 0px;
	color: #4285f4 !important;
	cursor: pointer;
	margin-right: 25px;
	text-transform: capitalize;
}

.navigationTab {
	color: #888888;
	font-size: 12px;
	width: 100%;
	background: #ecf1f4;
	text-transform: none;
	border-radius: 0;
	letter-spacing: 0;
}

.navigationTabCon {
	width: 344px;
	height: 36px;
	display: flex;
	margin-top: 10px;
}

.timesheetButton {
	position: absolute;
	z-index: 1000;
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	top: 40px;
	right: -6px;
	margin-left: 25px;
}

.timesheetIcon {
	color: #ffffff;
	margin-top: 1px;
	border-radius: 20%;
	width: 35px;
	height: 35px;
	font-size: 25px !important;
}

.timesheetButtonStyle {
	all: unset;
	padding: 2px !important;
	min-width: 1px;
}
