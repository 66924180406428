.DraftEditor-root {
	position: relative;
	border-radius: 4px;
	height: 32px;
	margin-bottom: 10px;
}

.DraftEditor-editorContainer {
	font-size: 12px;
	font-family: Manrope-medium;
	color: #292929;
	height: 42px;
}

.DraftEditor-editorContainer > div {
	height: 38px;
}

.public-DraftStyleDefault-block {
	margin: 5px 0px 3px 10px;
}

.public-DraftStyleDefault-block > a {
	background-color: #ffffff;
	color: #4285f4;
}

.public-DraftStyleDefault-block > a :hover {
	background-color: #ffffff;
	color: #4285f4;
}

/* Mentions style override */
.mtiwdxc {
	display: flex;
	padding: 7px 10px 7px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	align-items: center;
	font-size: 12px;
	font-family: 'Manrope-medium';
}

.myz2dw1 {
	padding: 7px 10px 7px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	background-color: #e6f3ff;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-family: 'Manrope-medium';
}

.m126ak5t {
	opacity: 1;
	max-height: 400px;
	overflow-y: auto;
}
