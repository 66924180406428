.pageTitle {
	font-family: Manrope-extrabold;
	margin-top: 20px;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
}
.addOpportunityButton:hover {
	background-color: #4285f4;
}
.addOpportunityButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 11px;
	min-width: 154px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.inviteUserButton {
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	font-family: Manrope-bold;
	font-size: 12px;
	color: #ffffff;
	min-width: 80px;
	height: 36px;
	margin-left: 20px;
	border: none;
	text-transform: uppercase;
	padding: 0px 16px;
	cursor: pointer;
}
.inviteUserButton:hover {
	background-color: #4285f4;
}
.inviteUserButton:disabled {
	color: rgba(0, 0, 0, 0.26);
	background-color: lightgrey;
	cursor: unset;
}
.iconButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #888888;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.activeIconButton {
	color: #4285f4;
	border: 1px solid #4285f4;
}
.priorityContainer {
	font-family: Manrope-semibold;
	font-size: 10px;
	border-radius: 10px;
	padding: 4px 8px 4px 8px;
	width: fit-content;
}
.priorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}
.priorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}
.priorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}
.statusContainer {
	width: 116px;
	height: 20px;
	background: #ebf3ff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	padding-left: 10px;
}
.filterStyle {
	position: absolute;
	bottom: 116px;
}
.toolContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 32px;
}
.filterButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #4285f4;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.assigneFilter {
	background: #ffffff;
	height: 36px;
	margin-left: 10px;
	align-items: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	width: 200px;
}
.navigationTabContainer {
	width: 344px;
	height: 36px;
	display: flex;
	margin-top: 10px;
}
.navigationTabButtons {
	font-family: Manrope-semibold;
	color: #888888;
	font-size: 12px;
	width: 50%;
	background: #ecf1f4;
	text-transform: none;
	border-radius: 0px;
	letter-spacing: 0px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.navigationTabButtons:first-child {
	border-right: 1px solid #e0e0e0;
	border-radius: 4px 0px 0px 4px !important;
}
.navigationTabButtons:last-child {
	border-radius: 0px 4px 4px 0px;
}
.activeNavigationTabButton {
	border-bottom: 2px solid #4285f4;
	color: #4285f4;
}
.searchBar {
	width: 344px;
}
.toolbar {
	display: flex;
	align-items: center;
}
.contentContainer {
	margin-top: 16px;
}
.navigationLinkContainer {
	width: 100%;
}
.opportunityCardContainer {
	margin-top: 16px;
	padding-top: 0px;
}
.overDueCardContent {
	width: 100%;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 8px;
	padding-bottom: 8px;
	border-left: 3px solid #ea4335;
}
.cardContainer {
	width: 100%;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 8px;
	padding-bottom: 8px;
}
.cardTitleContainer {
	width: 100%;
	padding: 2px 14px 0px 14px;
}
.cardTitle {
	font-family: Manrope-semibold;
	font-size: 12px;
	color: #292929;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	width: 200px;
}
.contentContainer {
	width: 100%;
	padding-top: 18px;
}
.actions {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: center;
	padding: 0px 14px 0px 14px;
}
.addTaskButton {
	width: 80px;
	height: 19px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	font-family: Manrope-semibold;
	font-size: 10px;
	color: #4285f4;
	padding: 2px 5px 2px 5px;
	text-transform: none;
	display: flex;
	align-items: center;
}
.overDue {
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.opportunityInfoContainer {
	height: 38px;
	position: relative;
	bottom: -12px;
	border-top: 1px solid #e0e0e0;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 0px 14px 0px 14px;
	margin: 0px 0px 2px 0px;
}
.priorityContainer {
	font-family: Manrope-semibold;
	font-size: 10px;
	border-radius: 10px;
	padding: 4px 8px 4px 8px;
}
.priorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}
.priorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}
.priorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}
.winPercentage {
	font-family: Manrope-medium;
	font-size: 10px;
	color: #5c5c5c;
	margin-right: 12px;
}
.winPercentageIcon {
	border-radius: 100px;
	width: 7px;
	height: 7px;
	margin-right: 5px;
}
.opportunityValue {
	font-family: Manrope-semibold;
	font-size: 10px;
	color: #4285f4;
}
.highWinPercentage {
	background: #4285f4;
}
.lowWinPercentage {
	background: #f4cf06;
}
.numberofOverDueTask {
	width: 12px;
	height: 12px;
	background: #ea4335;
	font-size: 8px;
	font-family: Manrope-extrabold;
	color: white;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	right: -20px;
	bottom: 6px;
}
.flexContainer {
	display: flex;
	align-items: center;
}

p {
	margin-bottom: 0;
	margin-top: 0;
}
