@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

@font-face {
    font-family: Manrope-bold;
    src: url(/static/media/Manrope-Bold.65675356.ttf) format("opentype");
}

@font-face {
    font-family: Manrope-semibold;
    src: url(/static/media/Manrope-SemiBold.255d425d.ttf) format("opentype");
}

@font-face {
    font-family: Manrope-medium;
    src: url(/static/media/Manrope-Medium.6196e0da.ttf) format("opentype");
}

@font-face {
    font-family: Manrope-regular;
    src: url(/static/media/Manrope-Regular.0b726174.ttf) format("opentype");
}

@font-face {
    font-family: Manrope-light;
    src: url(/static/media/Manrope-Light.55aaaa13.ttf) format("opentype");
}

@font-face {
    font-family: Manrope-extrabold;
    src: url(/static/media/Manrope-ExtraBold.47e356f6.ttf) format("opentype");
}


/* TODO We should refactor this and move to common place */
.headingName {
    font-family: Manrope-extrabold;
    margin-top: 10px;
}


/* TODO Find a way to override the antd styles and move this style to proper section */
.ant-statistic-content {
    font-family: Manrope-bold !important;
    font-size: 20px !important;
}

input[type='text'],
input[type='date'] {
    padding-top: 16px;
    height: 26px;
}

/* modify the Table header */
.ant-table-thead > tr >th{
  font-family: Manrope-extrabold;
  font-size: 10px;
  color: #5C5C5C;
}

/* modify list text */
.ant-table-tbody > tr >td{
  font-family: Manrope-medium;
  font-size: 12px;
  color: #5C5C5C;
  }
.projectTaskKanbanToolBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding-left: 10px;
}
.projectTaskKanbanBreadCrumb {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.projectTaskKanbanTitle {
	font-size: 12px;
	font-family: Manrope-Bold;
	color: #5c5c5c;
}
.arrowIcon {
	color: #5c5c5c;
	width: 21px;
	padding-left: 7px;
}
.projectTaskKanbanProjectName {
	color: #4285f4;
	font-size: 12px;
	font-family: Manrope-Bold;
	padding-left: 14px;
	width: 230px;
}
.projectTaskKanbanToolbarContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: '10px'
}
.projectTaskKanbanFilterButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #4285f4;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.projectTaskKanbanIconButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #888888;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.projectTaskKanbanActiveIconButton {
	color: #4285f4;
	border: 1px solid #4285f4;
}
.projectTaskKanbanAddTaskButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 14px;
	min-width: 108px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.projectTaskKanbanAddTaskButton:hover {
	background-color: #4285f4;
}

.projectTaskKanbanAssigneeFilterContainer {
	background: #ffffff;
	height: 36px;
	margin-left: 10px;
	align-items: left;
}
.projectTaskKanbanFilterContainer {
	display: flex;
	align-items: left;
}
.projecttaskkanbanfilterbutton {
	margin-right: 10px;
}

.projectTaskContainer {
	text-align: left;
	padding-bottom: 10px;
}
.projectTaskPriorityBadge {
	padding: 2px 5px;
	border-radius: 10px;
	margin-left: 10px;
	text-align: center;
}
.projectTaskPriorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}
.projectTaskPriorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}
.projectTaskPriorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}
.projectTaskDescriptionStyle {
	padding-left: 10px;
}
.projectTaskHeaderStyle {
	width: 216px;
	color: #292929;
	font-size: 12px;
	font-family: Manrope-semibold;
	padding: 0px 14px 4px 14px;
	margin-left: 6px;
}
.projectTaskDescription {
	width: 300px;
	font: normal normal 600 12px/25px Manrope;
	color: #6f6f6f;
	padding: 12px 14px 12px 15px;
	margin-left: 6px;
}
.projectTaskIdStyle {
	display: flex;
	justify-content: space-between;
}
.projectCategoryContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 22px;
}
.projectTaskCategoryName {
	font-size: 12px;
	margin: 0px 0px 3px 11px;
	background: #c0b6f2;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	color: #403294;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 200px;
	cursor: pointer;
	font-family: Manrope-medium;
}
.projectTaskContainerStyle {
	padding-left: 8px;
}
.avatar {
	height: 25;
	width: 25;
	cursor: pointer;
}
.projectTaskCreatedDate {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
	cursor: pointer;
}
.projectTaskCardContainer {
	width: 100%;
	min-height: 110px;
	background: #ffffff 0% 0% no-repeat padding-box;
}
.projectTaskOverdueCardContainer {
	width: 100%;
	border-left: 3px solid #ea4335;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
}
.projectTaskPrioritUNdefined {
	padding-right: 46px;
}
.projectTaskChipStyle {
	font-size: 10px;
	/* padding: 4px 8px 4px 8px; */
	border-radius: 10px;
	cursor: pointer;
}
.projectTaskCardFooter {
	padding: 7px 4px 8px 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}
.projectTaskNormalDue {
	font: normal normal 600 10px/20px Manrope;
	color: #34a853;
	max-width: 50px;
}
.projectTaskOverDue {
	font: normal normal 600 10px/20px Manrope;
	color: #ea4335;
}
.projectTaskDueToday {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
}
.taskIcon {
	width: 16px !important;
	height: 16px !important;
	margin-top: 10px !important;
	color: #4285f4;
	margin-right: 8px !important;
	cursor: pointer;
}
.taskIdContainer {
	font: normal normal 800 10px/20px Manrope;
	color: #888888;
	margin-top: 8px;
	text-transform: uppercase;
	margin-left: 7px;
	padding-left: 8px;
}
.subTask {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: -5px;
}
.numberofSubTask {
	width: 12px;
	height: 12px;
	background: #817b7b;
	font-size: 8px;
	font-family: Manrope-extrabold;
	color: white;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	right: -16px;
	bottom: 5px;
}
.hoverText {
	display: flex !important;
	justify-content: flex-start;
	margin-left: 48px;
	margin-top: 5px;
	color: #4285f4;
	font: normal normal 600 14px/20px Manrope;
	cursor: pointer;
}
.hoverText:hover {
	text-decoration: underline;
	color: #4285f4;
	cursor: pointer;
}

.progressBarContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.backButton {
	width: 89px;
	height: 36px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	opacity: 1;
	font: normal normal bold 12px/32px Manrope;
	letter-spacing: 0px;
	color: #4285f4 !important;
	cursor: pointer;
	margin-right: 25px;
	text-transform: capitalize;
}
.projectTaskColumnName {
	font-size: 12px;
	margin: 0px 0px 3px 11px;
	background: #c0b6f2;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	color: #403294;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 200px;
	cursor: pointer;
	font-family: Manrope-medium;
}
.columnHeader{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 182px;
	cursor: pointer;
}
.kanbanContainer {
	display: flex;
}

.kanbanColumContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.columnHeader {
	padding-left: 6px;
	font-size: 12px;
	font-weight: bold;
	color: #5c5c5c;
	font-family: Manrope-extrabold;
	margin-top: 8px;
	margin-bottom: 4px;
}
.kanbanColumnRoot {
	flex: 1 1;
	padding: 8px 5px 16px 5px;
	background-color: #ecf1f4;
	min-width: 245px;
	height: 91vh;
	margin-right: 10px;
	border-radius: 8px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.droppable {
	display: flex;
	flex-direction: column;
	border-radius: 5;
	padding: 5;
	height: 80vh;
	overflow-y: auto;
	/* &.isDraggingOver {
    background-color: #dadadf;
  } */
}
.totalValue {
	font-size: 13px;
	font-family: Manrope-extraBold;
	opacity: 1;
}

.totalLabel {
	font-size: 11px;
	font-family: Manrope-semiBold;
	opacity: 0.5;
}

.kanbanColumnFooterContainer {
	padding-right: 15px;
}

.kanbanCardRoot {
	margin-bottom: 16px;
}

.kanbanCardContent {
	display: flex;
}



/* TitTap Editor Design */
.textDetails .ProseMirror {
  padding: 10px;
  border-top: 1px solid #E0E0E0;
  background: white;
  border-radius: 0 0 5px 5px;
  height: 75px;
  overflow-y: auto;
  cursor: pointer;
}
.ProseMirror {
  padding: 10px;
  border-top: 1px solid #E0E0E0;
  background: white;
  border-radius: 0 0 5px 5px;
  height: 200px;
  overflow-y: auto;
  cursor: pointer;
}
.ProseMirror:focus {
  border: none;
  outline: 1px solid #E0E0E0;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #ecf1f4;
  color: black;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

/* ............................ */
.textEditor {
  border-radius: 5px;
  border: 1px solid #E0E0E0;
}
.textDetails .menuBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textDetails .menuBar button {
  font-size: 12px;
  margin: 4px;
  outline: none;
  border: none;
  background: none;
  color: rgb(70, 70, 70);
  cursor: pointer;
}
.menuBar {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menuBar button {
  font-size: 12px;
  margin: 7px;
  outline: none;
  border: none;
  background: none;
  color: rgb(70, 70, 70);
  cursor: pointer;
}
.menuBar button:last-child {
  margin-right: 7px;
}

.heading3 {
  font-size: 15px;
}

button.is_active {
  background: rgb(197, 197, 197);
  padding: 2px 3px;
  border-radius: 2px;
}
.DraftEditor-root {
	position: relative;
	border-radius: 4px;
	height: 32px;
	margin-bottom: 10px;
}

.DraftEditor-editorContainer {
	font-size: 12px;
	font-family: Manrope-medium;
	color: #292929;
	height: 42px;
}

.DraftEditor-editorContainer > div {
	height: 38px;
}

.public-DraftStyleDefault-block {
	margin: 5px 0px 3px 10px;
}

.public-DraftStyleDefault-block > a {
	background-color: #ffffff;
	color: #4285f4;
}

.public-DraftStyleDefault-block > a :hover {
	background-color: #ffffff;
	color: #4285f4;
}

/* Mentions style override */
.mtiwdxc {
	display: flex;
	padding: 7px 10px 7px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	align-items: center;
	font-size: 12px;
	font-family: 'Manrope-medium';
}

.myz2dw1 {
	padding: 7px 10px 7px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	background-color: #e6f3ff;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-family: 'Manrope-medium';
}

.m126ak5t {
	opacity: 1;
	max-height: 400px;
	overflow-y: auto;
}

.pageTitle {
	font-family: Manrope-extrabold;
	margin-top: 20px;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
}
.addOpportunityButton:hover {
	background-color: #4285f4;
}
.addOpportunityButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 11px;
	min-width: 154px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.inviteUserButton {
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	font-family: Manrope-bold;
	font-size: 12px;
	color: #ffffff;
	min-width: 80px;
	height: 36px;
	margin-left: 20px;
	border: none;
	text-transform: uppercase;
	padding: 0px 16px;
	cursor: pointer;
}
.inviteUserButton:hover {
	background-color: #4285f4;
}
.inviteUserButton:disabled {
	color: rgba(0, 0, 0, 0.26);
	background-color: lightgrey;
	cursor: unset;
}
.iconButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #888888;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.activeIconButton {
	color: #4285f4;
	border: 1px solid #4285f4;
}
.priorityContainer {
	font-family: Manrope-semibold;
	font-size: 10px;
	border-radius: 10px;
	padding: 4px 8px 4px 8px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.priorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}
.priorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}
.priorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}
.statusContainer {
	width: 116px;
	height: 20px;
	background: #ebf3ff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	padding-left: 10px;
}
.filterStyle {
	position: absolute;
	bottom: 116px;
}
.toolContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 32px;
}
.filterButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #4285f4;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}
.assigneFilter {
	background: #ffffff;
	height: 36px;
	margin-left: 10px;
	align-items: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	width: 200px;
}
.navigationTabContainer {
	width: 344px;
	height: 36px;
	display: flex;
	margin-top: 10px;
}
.navigationTabButtons {
	font-family: Manrope-semibold;
	color: #888888;
	font-size: 12px;
	width: 50%;
	background: #ecf1f4;
	text-transform: none;
	border-radius: 0px;
	letter-spacing: 0px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.navigationTabButtons:first-child {
	border-right: 1px solid #e0e0e0;
	border-radius: 4px 0px 0px 4px !important;
}
.navigationTabButtons:last-child {
	border-radius: 0px 4px 4px 0px;
}
.activeNavigationTabButton {
	border-bottom: 2px solid #4285f4;
	color: #4285f4;
}
.searchBar {
	width: 344px;
}
.toolbar {
	display: flex;
	align-items: center;
}
.contentContainer {
	margin-top: 16px;
}
.navigationLinkContainer {
	width: 100%;
}
.opportunityCardContainer {
	margin-top: 16px;
	padding-top: 0px;
}
.overDueCardContent {
	width: 100%;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 8px;
	padding-bottom: 8px;
	border-left: 3px solid #ea4335;
}
.cardContainer {
	width: 100%;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 8px;
	padding-bottom: 8px;
}
.cardTitleContainer {
	width: 100%;
	padding: 2px 14px 0px 14px;
}
.cardTitle {
	font-family: Manrope-semibold;
	font-size: 12px;
	color: #292929;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	width: 200px;
}
.contentContainer {
	width: 100%;
	padding-top: 18px;
}
.actions {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: center;
	padding: 0px 14px 0px 14px;
}
.addTaskButton {
	width: 80px;
	height: 19px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	font-family: Manrope-semibold;
	font-size: 10px;
	color: #4285f4;
	padding: 2px 5px 2px 5px;
	text-transform: none;
	display: flex;
	align-items: center;
}
.overDue {
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.opportunityInfoContainer {
	height: 38px;
	position: relative;
	bottom: -12px;
	border-top: 1px solid #e0e0e0;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 0px 14px 0px 14px;
	margin: 0px 0px 2px 0px;
}
.priorityContainer {
	font-family: Manrope-semibold;
	font-size: 10px;
	border-radius: 10px;
	padding: 4px 8px 4px 8px;
}
.priorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}
.priorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}
.priorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}
.winPercentage {
	font-family: Manrope-medium;
	font-size: 10px;
	color: #5c5c5c;
	margin-right: 12px;
}
.winPercentageIcon {
	border-radius: 100px;
	width: 7px;
	height: 7px;
	margin-right: 5px;
}
.opportunityValue {
	font-family: Manrope-semibold;
	font-size: 10px;
	color: #4285f4;
}
.highWinPercentage {
	background: #4285f4;
}
.lowWinPercentage {
	background: #f4cf06;
}
.numberofOverDueTask {
	width: 12px;
	height: 12px;
	background: #ea4335;
	font-size: 8px;
	font-family: Manrope-extrabold;
	color: white;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	right: -20px;
	bottom: 6px;
}
.flexContainer {
	display: flex;
	align-items: center;
}

p {
	margin-bottom: 0;
	margin-top: 0;
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: black;
}
.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: black;
}
.orgchart-container {
  border: none !important;
}
.myChart {
  background-image: none !important;
}
.public-DraftEditorPlaceholder-inner {
	font-size: x-small;
	font-style: italic;
	padding: 5px;
}

.projectTaskKanbanToolBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding-left: 10px;
}

.projectTaskKanbanBreadCrumb {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.projectTaskKanbanTitle {
	font-size: 12px;
	font-family: Manrope-Bold;
	color: #5c5c5c;
}

.arrowIcon {
	color: #5c5c5c;
	width: 21px;
	padding-left: 7px;
}

.projectTaskKanbanProjectName {
	color: #4285f4;
	font-size: 12px;
	font-family: Manrope-Bold;
	padding-left: 14px;
	width: 230px;
}

.projectTaskKanbanToolbarContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-left: 10px;
}

.projectTaskKanbanFilterButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #4285f4;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}

.projectTaskKanbanIconButton {
	width: 36px;
	height: 36px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin-right: 10px;
	color: #888888;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	cursor: pointer;
}

.projectTaskKanbanActiveIconButton {
	color: #4285f4;
	border: 1px solid #4285f4;
}

.projectTaskKanbanAddTaskButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 14px;
	min-width: 108px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.projectTaskKanbanUploadTaskButton {
	font: normal normal bold 12px/37px Manrope;
	color: #ffffff;
	height: 36px;
	padding: 10px 14px;
	min-width: 108px;
	background: #4285f4 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 12px #4285f43b;
	border-radius: 4px;
	text-transform: none;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	grid-gap: 6px;
	gap: 6px;
	margin-left: 4px;
}

.projectTaskKanbanAddTaskButton:hover {
	background-color: #4285f4;
}

.projectTaskKanbanAssigneeFilterContainer {
	background: #ffffff;
	height: 36px;
	margin-left: 10px;
	align-items: left;
}

.projectTaskKanbanFilterContainer {
	display: flex;
	align-items: left;
}

.projecttaskkanbanfilterbutton {
	margin-right: 10px;
}

.projectTaskContainer {
	text-align: left;
	padding-bottom: 10px;
}

.projectTaskPriorityBadge {
	padding: 2px 5px;
	border-radius: 10px;
	margin-left: 10px;
	text-align: center;
}

.projectTaskPriorityHigh {
	background-color: #fff3f2;
	color: #ea4335;
}

.projectTaskPriorityMedium {
	background-color: #ebf3ff;
	color: #4285f4;
}

.projectTaskPriorityLow {
	background-color: #e9f7ec;
	color: #34a853;
}

.projectTaskDescriptionStyle {
	padding-left: 10px;
}

.projectTaskHeaderStyle {
	width: 216px;
	color: #292929;
	font-size: 12px;
	font-family: Manrope-semibold;
	padding: 0px 14px 4px 14px;
	margin-left: 6px;
}

.projectTaskDescription {
	width: 300px;
	font: normal normal 600 12px/25px Manrope;
	color: #6f6f6f;
	padding: 12px 14px 12px 15px;
	margin-left: 6px;
}

.projectTaskIdStyle {
	display: flex;
	justify-content: space-between;
}

.projectCategoryContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 22px;
}

.projectTaskCategoryName {
	font-size: 12px;
	margin: 0px 0px 3px 11px;
	background: #c0b6f2;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	color: #403294;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 150px;
	cursor: pointer;
	font-family: Manrope-medium;
}

.projectTaskContainerStyle {
	padding-left: 8px;
}

.avatar {
	height: 25;
	width: 25;
	cursor: pointer;
}

.projectTaskCreatedDate {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
	cursor: pointer;
}

.projectTaskCardContainer {
	width: 100%;
	min-height: 110px;
	background: #ffffff 0% 0% no-repeat padding-box;
}

.projectTaskOverdueCardContainer {
	width: 100%;
	border-left: 3px solid #ea4335;
	min-height: 98px;
	background: #ffffff 0% 0% no-repeat padding-box;
}

.projectTaskPrioritUNdefined {
	padding-right: 46px;
}

.projectTaskChipStyle {
	font-size: 10px;
	/* padding: 4px 8px 4px 8px; */
	border-radius: 10px;
	cursor: pointer;
}

.projectTaskCardFooter {
	padding: 7px 4px 8px 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.projectTaskNormalDue {
	font: normal normal 600 10px/20px Manrope;
	color: #34a853;
	max-width: 50px;
}

.projectTaskOverDue {
	font: normal normal 600 10px/20px Manrope;
	color: #ea4335;
}

.projectTaskDueToday {
	font: normal normal 600 10px/20px Manrope;
	color: #4285f4;
}

.taskIcon {
	width: 16px !important;
	height: 16px !important;
	margin-top: 10px !important;
	color: #4285f4;
	margin-right: 8px !important;
	cursor: pointer;
}

.taskIdContainer {
	font: normal normal 800 10px/20px Manrope;
	color: #888888;
	margin-top: 8px;
	text-transform: uppercase;
	margin-left: 7px;
	padding-left: 8px;
}

.subTask {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.numberofSubTask {
	width: 12px;
	height: 12px;
	background: #817b7b;
	font-size: 8px;
	font-family: Manrope-extrabold;
	color: white;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	right: -16px;
	bottom: 5px;
}

.hoverText {
	display: flex !important;
	justify-content: flex-start;
	margin-left: 48px;
	margin-top: 5px;
	color: #4285f4;
	font: normal normal 600 14px/20px Manrope;
	cursor: pointer;
}

.hoverText:hover {
	text-decoration: underline;
	color: #4285f4;
	cursor: pointer;
}

.progressBarContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.backButton {
	width: 89px;
	height: 36px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	opacity: 1;
	font: normal normal bold 12px/32px Manrope;
	letter-spacing: 0px;
	color: #4285f4 !important;
	cursor: pointer;
	margin-right: 25px;
	text-transform: capitalize;
}

.navigationTab {
	color: #888888;
	font-size: 12px;
	width: 100%;
	background: #ecf1f4;
	text-transform: none;
	border-radius: 0;
	letter-spacing: 0;
}

.navigationTabCon {
	width: 344px;
	height: 36px;
	display: flex;
	margin-top: 10px;
}

.timesheetButton {
	position: absolute;
	z-index: 1000;
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	top: 40px;
	right: -6px;
	margin-left: 25px;
}

.timesheetIcon {
	color: #ffffff;
	margin-top: 1px;
	border-radius: 20%;
	width: 35px;
	height: 35px;
	font-size: 25px !important;
}

.timesheetButtonStyle {
	all: unset;
	padding: 2px !important;
	min-width: 1px;
}

