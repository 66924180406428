.kanbanContainer {
	display: flex;
}

.kanbanColumContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.columnHeader {
	padding-left: 6px;
	font-size: 12px;
	font-weight: bold;
	color: #5c5c5c;
	font-family: Manrope-extrabold;
	margin-top: 8px;
	margin-bottom: 4px;
}
.kanbanColumnRoot {
	flex: 1;
	padding: 8px 5px 16px 5px;
	background-color: #ecf1f4;
	min-width: 245px;
	height: 91vh;
	margin-right: 10px;
	border-radius: 8px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.droppable {
	display: flex;
	flex-direction: column;
	border-radius: 5;
	padding: 5;
	height: 80vh;
	overflow-y: auto;
	/* &.isDraggingOver {
    background-color: #dadadf;
  } */
}
.totalValue {
	font-size: 13px;
	font-family: Manrope-extraBold;
	opacity: 1;
}

.totalLabel {
	font-size: 11px;
	font-family: Manrope-semiBold;
	opacity: 0.5;
}

.kanbanColumnFooterContainer {
	padding-right: 15px;
}

.kanbanCardRoot {
	margin-bottom: 16px;
}

.kanbanCardContent {
	display: flex;
}
