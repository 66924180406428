@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

@font-face {
    font-family: Manrope-bold;
    src: url("assets/fonts/Manrope-Bold.ttf") format("opentype");
}

@font-face {
    font-family: Manrope-semibold;
    src: url("assets/fonts/Manrope-SemiBold.ttf") format("opentype");
}

@font-face {
    font-family: Manrope-medium;
    src: url("assets/fonts/Manrope-Medium.ttf") format("opentype");
}

@font-face {
    font-family: Manrope-regular;
    src: url("assets/fonts/Manrope-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Manrope-light;
    src: url("assets/fonts/Manrope-Light.ttf") format("opentype");
}

@font-face {
    font-family: Manrope-extrabold;
    src: url("assets/fonts/Manrope-ExtraBold.ttf") format("opentype");
}


/* TODO We should refactor this and move to common place */
.headingName {
    font-family: Manrope-extrabold;
    margin-top: 10px;
}


/* TODO Find a way to override the antd styles and move this style to proper section */
.ant-statistic-content {
    font-family: Manrope-bold !important;
    font-size: 20px !important;
}

input[type='text'],
input[type='date'] {
    padding-top: 16px;
    height: 26px;
}
