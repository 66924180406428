/* modify the Table header */
.ant-table-thead > tr >th{
  font-family: Manrope-extrabold;
  font-size: 10px;
  color: #5C5C5C;
}

/* modify list text */
.ant-table-tbody > tr >td{
  font-family: Manrope-medium;
  font-size: 12px;
  color: #5C5C5C;
  }