.orgchart.myChart > ul > li > ul li::before {
  border-top-color: black;
}
.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: black;
}
.orgchart-container {
  border: none !important;
}
.myChart {
  background-image: none !important;
}